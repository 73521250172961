import { Injectable } from '@angular/core'
import { BuiEventsService } from '@fleet-customer/booster-ui'
import { Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class EventsService {
  public readonly windowResize$ = this.buiEventsService.windowResize$
  public readonly navigationStart$ = this.buiEventsService.navigationStart$

  private readonly scrollContentOutletSubject$ = new Subject<HTMLElement>()
  public readonly scrollContentOutlet$ =
    this.scrollContentOutletSubject$.asObservable()

  constructor(private buiEventsService: BuiEventsService) {}

  public triggerScrollContentOutlet(scrollToElement: HTMLElement): void {
    setTimeout(() => this.scrollContentOutletSubject$.next(scrollToElement))
  }
}
